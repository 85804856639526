<template>
    <div class="profile">

        <div class="h4 mb-4">Профиль</div>

        <div class="mb-4">
            <div
                class="bg-light d-inline-block p-3"
                style="min-width: 443px;"
            >

                <div class="h3 font-weight-light">{{ user.name }} {{ user.surname }} {{ user.middlename }}</div>

                <div class="h5 font-weight-light mb-3">{{ user.contractor }}</div>

                <b-row
                    v-if="user.phone"
                    class="mb-2">

                    <b-col cols="6">Телефон:</b-col>

                    <b-col cols="6" class="text-primary">{{ user.phone | VMask('# (###) ###-##-##')}}</b-col>

                </b-row>

                <b-row>

                    <b-col cols="6">Электронная почта:</b-col>

                    <b-col cols="6" class="text-primary">{{ user.email }}</b-col>

                </b-row>

            </div>
        </div>

    </div>
</template>

<script>

import { required, sameAs } from "vuelidate/lib/validators"

export default {
    name: "Profile",
    validations: {
        lastPassword: {required},
        password: {required},
        repeatPassword: {
            sameAsPassword: sameAs('password')
        }
    },
    computed: {
        user() {
            return this.$store.state.account.user
        }
    },
    data: () => ({
        lastPassword: '',
        password: '',
        repeatPassword: '',
        breadcrumbs: [
            {
                title: 'Главная',
                link: '/'
            },
            {
                title: 'Профиль'
            }
        ]
    })
}
</script>

<style
    lang="sass"
    scoped
>

</style>
